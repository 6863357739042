.order-tracking {
  padding: 20px;
  background-color: #1c1c1e;
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  max-width: 600px;
  margin: auto;
}

.order-tracking h4 {
  color: #bb86fc;
  margin-bottom: 20px;
  text-align: center;
}

input {
  background-color: #333;
  color: #f5f5f5;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  border: none;
  width: 100%;
}

button {
  background-color: #bb86fc;
  color: #1c1c1e;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #9b68cc;
}

.order-details {
  margin-top: 20px;
  background-color: #2c2c2e;
  padding: 15px;
  border-radius: 8px;
  color: #f5f5f5;
  width: 100%;
}

.order-image {
  height: 150px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  margin-top: 10px;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .order-tracking {
    width: 90%;
  }

  .order-image {
    height: 100px;
  }
}
