.footer {
  background-color: var(--purple);
  padding: 40px 0;
  color: var(--white);

  width: 100%;
}
.footer-title {
  font-family: var(--hand-font) !important;
  font-size: 2.5rem !important;
}

.footer-grid {
  display: flex;
  justify-content: space-between;
}

.footer-section {
  text-align: center;
}
.footer-section h6 {
  font-size: 2.2rem !important;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-link {
  color: var(--white);
  text-decoration: none;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: var(--blue);
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-icon {
  color: var(--white);
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.1);
  color: var(--blue);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .footer-grid {
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 20px;
  }
}
