:root {
  /* Primary Background */
  --primary-bg: #111111;

  /* Secondary Background */
  --secondary-bg: #222222;

  /* Text Color */
  --light-gray: #e0e0e0;

  /* Accent Color (Neon Blue) */
  --blue: #00adb5;

  /* Secondary Accent Color (Muted Pink) */
  --pink: #ff4081;
  --purple: rgb(181, 0, 237);

  /* Highlight Color (Soft White) */
  --white: #f5f5f5;

  /* Error or Warning Color */
  --error-red: #ff3b3b;

  /* Fonts   */
  --title-font: "Wosker";
  --body-font: "MaximaNouva";
  --hand-font: "Mayor";
}

/* Local Font Import */

@font-face {
  font-family: "Mayor";
  src: url("./Mayor.ttf") format("truetype");
}
@font-face {
  font-family: "Wosker";
  src: url("./Wosker.ttf") format("truetype");
}
@font-face {
  font-family: "MaximaNouva";
  src: url("./MaximaNouva.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: repeating-linear-gradient(
      135deg,
      rgb(0, 0, 0) 0px,
      rgb(0, 0, 0) 10px,
      transparent 10px,
      transparent 11px
    ),
    repeating-linear-gradient(
      22.5deg,
      rgb(0, 0, 0) 0px,
      rgb(0, 0, 0) 10px,
      transparent 10px,
      transparent 11px
    ),
    linear-gradient(
      90deg,
      var(--blue),
      hsl(266, 74%, 56%),
      var(--pink),
      var(--white)
    ) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font) !important;
  font-weight: 700;
}

p,
span,
li {
  font-family: var(--body-font) !important;
  font-weight: 300;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--light-gray);
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: var(--light-gray);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, var(--blue)),
    color-stop(0.72, var(--secondary-bg))
  );
}

a {
  text-decoration: none;
  color: inherit;
}

/* Set placeholder text color to white */
input::placeholder,
textarea::placeholder {
  color: #ffffff !important;
  opacity: 1; /* Ensures the color is fully applied */
}
