.gallery-upload {
  padding: 20px;
  background-color: #1c1c1e;
  color: #f5f5f5;
  text-align: center;
}

.page-title {
  color: #bb86fc;
  margin-bottom: 20px;
}

.file-input {
  display: block;
  margin: 10px auto;
  width: 80%;
  max-width: 300px;
}

.upload-button {
  background-color: #bb86fc;
  color: #1c1c1e;
  margin-top: 10px;
}
