.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  background-color: var(--primary-bg); /* Background color of the loader */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; /* Ensure the loader is on top of everything */
}

.logo-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulsing-logo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 1.5s infinite;
}

.pulsing-logo img {
  width: 145px; /* Adjust the size of the logo */
}

.loading-text {
  margin-top: 20px;
  font-size: 28px;
  font-family: var(--body-font);

  font-weight: bold;
  color: var(--blue);
  animation: fadeInOut 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
