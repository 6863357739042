.admin-dashboard {
  background-color: #1c1c1e;
  color: #f5f5f5;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-header {
  text-align: center;
  margin-bottom: 20px;
}

.admin-title {
  color: #bb86fc;
  font-weight: bold;
  font-size: 2rem;
}

.divider {
  background-color: #bb86fc;
  margin: 10px 0;
  height: 2px;
  width: 80%;
}

.admin-nav {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.admin-nav .MuiButtonBase-root {
  color: #fff;
  background-color: #333;
  border: 1px solid #bb86fc;
  transition: background-color 0.3s, transform 0.3s;
  padding: 10px 20px;
  font-size: 0.9rem;
}

.admin-nav .MuiButtonBase-root:hover,
.admin-nav .active {
  background-color: #bb86fc;
  color: #1c1c1e;
  transform: scale(1.05);
}

.admin-content {
  width: 100%;
  max-width: 800px;
  background-color: #2c2c2e;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.posts-list {
  width: 100%;
  margin-top: 20px;
}

.posts-list .MuiListItem-root {
  background-color: #1c1c1e;
  margin-bottom: 10px;
  border: 1px solid #bb86fc;
  border-radius: 5px;
  padding: 15px;
  transition: transform 0.3s;
}

.posts-list .MuiListItem-root:hover {
  transform: scale(1.02);
  background-color: #2c2c2e;
}

.back-button {
  background-color: #bb86fc;
  color: #1c1c1e;
  margin-bottom: 15px;
}

@media (max-width: 600px) {
  .admin-content {
    padding: 15px;
  }

  .admin-title {
    font-size: 1.5rem;
  }

  .admin-nav {
    flex-direction: column;
    align-items: center;
  }

  .admin-nav .MuiButtonBase-root {
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
  }

  .divider {
    width: 100%;
  }

  .posts-list .MuiListItem-root {
    padding: 10px;
  }
}
