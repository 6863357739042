.create-blog-post {
  padding: 20px;
  background-color: #1c1c1e;
  color: #f5f5f5;
  text-align: center;
  border-radius: 8px;
}

.page-title {
  color: #bb86fc;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.input-field {
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  background-color: #2c2c2e;
  border-radius: 5px;
}

.file-input {
  display: block;
  margin: 15px auto;
  width: 100%;
  max-width: 500px;
  background-color: #2c2c2e;
  padding: 10px;
  border-radius: 5px;
}

.submit-button {
  background-color: #bb86fc;
  color: #1c1c1e;
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1rem;
  transition: transform 0.3s;
}

.submit-button:hover {
  transform: scale(1.05);
  background-color: #d197fc;
}

@media (max-width: 600px) {
  .create-blog-post {
    padding: 15px;
  }

  .page-title {
    font-size: 1.5rem;
  }

  .input-field,
  .file-input,
  .submit-button {
    width: 100%;
    max-width: none;
  }
}
