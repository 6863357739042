.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #1c1c1e;
  color: #f5f5f5;
  padding: 20px;
}

.login-title {
  color: #bb86fc;
  margin-bottom: 20px;
  text-align: center;
}

.login-form {
  width: 100%;
  max-width: 400px;
  background-color: #2c2c2e;
  padding: 20px;
  border-radius: 8px;
}

.input-field {
  width: 100%;
  margin-bottom: 15px;
  background-color: #3c3c3e;
  border-radius: 4px;
}

.login-button {
  background-color: #bb86fc;
  color: #1c1c1e;
  width: 100%;
}

.error-message {
  margin-bottom: 10px;
  text-align: center;
  color: #ff4f4f;
}
