.navbar {
  background-image: radial-gradient(
    circle at top right,
    rgb(36, 9, 119) 0%,
    rgb(36, 9, 119) 48%,
    rgb(72, 7, 149) 48%,
    rgb(72, 7, 149) 53%,
    rgb(109, 5, 178) 53%,
    rgb(109, 5, 178) 56%,
    rgb(145, 2, 208) 56%,
    rgb(145, 2, 208) 69%,
    rgb(181, 0, 237) 69%,
    rgb(181, 0, 237) 100%
  ) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.navbar-title {
  font-family: var(--hand-font) !important;
  font-size: 3.5rem !important;
  color: var(--white);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-link {
  text-decoration: none;
  color: var(--white);
  font-family: var(--title-font);
  font-size: 1.4rem;
  transition: color 0.3s ease;
}

.nav-button:hover .nav-link {
  color: var(--blue);
}

.menu-icon {
  display: none;
}

.nav-button {
  margin: 0 10px;
  transition: transform 0.3s ease;
}

.nav-button:hover {
  transform: scale(1.1);
}

/* Drawer Styling */
.drawer-paper {
  width: 240px;
  background-color: var(--primary-bg) !important;
}

.drawer-content {
  padding: 20px;
}

.drawer-content .MuiListItemText-root {
  color: var(--white);
}

.drawer-content .MuiListItem-root:hover .MuiListItemText-root {
  color: var(--blue);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  .navbar-title {
    margin: "auto" !important;
  }

  .menu-icon {
    display: block;
    color: var(--white);
  }
}
