.blog-page {
  padding: 20px;
  background-color: #1c1c1e;
  color: #f5f5f5;
}

.page-title {
  color: #bb86fc;
  text-align: center;
  margin-bottom: 20px;
}

.blog-container {
  display: flex;
  justify-content: center;
}

.blog-card {
  background-color: #2c2c2e;
  color: #f5f5f5;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.card-content {
  display: flex;
  align-items: center;
}

.post-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.blog-title {
  color: #bb86fc;
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.tags-container {
  margin: 8px 0;
}

.post-tag {
  background-color: #bb86fc;
  color: #1c1c1e;
  font-size: 0.8rem;
  padding: 2px 8px;
  border-radius: 12px;
  margin-right: 5px;
}

.blog-content {
  color: #d1d1d1;
  font-size: 0.9rem;
}
.post-box {
  display: flex;
  gap: 20px;
}
.post-image {
  width: 30vw;
  border-radius: 10px;
}
.post-content {
  font-size: calc(10px + 2vw);
  white-space: pre-line;
}

@media (max-width: 768px) {
  .post-box {
    display: flex;
    flex-direction: column;

    gap: 10px;
  }
  .post-image {
    width: 80vw;
  }
  .post-content {
    font-size: calc(15px + 2vw);
  }
}
