/* Section styling */

section {
  padding: 60px 20px;
  background-color: var(--primary-bg);
  color: var(--light-gray);
  margin-bottom: 40px;
}
section p {
  text-align: center;
  font-size: 4.2rem;
}
h2,
h4 {
  color: var(--purple) !important;
}

/* Introduction Section */
.intro-section {
  background-color: var(--secondary-bg);
  padding: 20px 20px;
  text-align: center;
}

.intro-logo {
  padding: 0;
  margin: 0;
}

.intro-section h2 {
  font-size: 3.5rem;
  color: var(--white);
}

.intro-section p {
  font-size: 2.2rem;
}

/* About Section */
.about-section {
  background-color: var(--secondary-bg);
  text-align: left;
}
.about-text {
  display: flex;
  gap: 10px;
}

.about-img {
  border-radius: 10px;
  width: 25%;
}
.about-section h4 {
  color: var(--white);
}

/* Blog and Artwork Section */
.blog-section,
.latest-artwork-section {
  background-color: var(--secondary-bg);
}

/* Card Styling */
.MuiCard-root {
  background-color: var(--secondary-bg);
  color: var(--light-gray);
  cursor: pointer;
  transition: all 0.8s ease !important;
}

.MuiCard-root:hover {
  background-color: var(--blue);
}

/* Button styling */
.MuiButton-containedPrimary {
  background-color: var(--blue);
}

.MuiButton-containedPrimary:hover {
  background-color: var(--pink);
}
/* Mobile responsiveness */
@media (max-width: 768px) {
  .about-text {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    gap: 10px;
  }
  .about-img {
    border-radius: 10px;
    width: 45%;
    margin: auto;
  }
  .intro-logo {
    width: 75%;
  }
}
