:root {
  --blue: #00adb5;
  --pink: #ff4081;
  --white: #f5f5f5;
  --dark-bg: #1e1e1e;
  --accent-color: #bb86fc;
}

.contact-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: repeating-linear-gradient(
      135deg,
      rgb(0, 0, 0) 0px,
      rgb(0, 0, 0) 10px,
      transparent 10px,
      transparent 11px
    ),
    repeating-linear-gradient(
      22.5deg,
      rgb(0, 0, 0) 0px,
      rgb(0, 0, 0) 10px,
      transparent 10px,
      transparent 11px
    ),
    linear-gradient(
      90deg,
      var(--blue),
      hsl(266, 74%, 56%),
      var(--pink),
      var(--white)
    ) !important;
  color: var(--white);
}

.form-container {
  background: var(--dark-bg);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.contact-heading {
  color: var(--accent-color);
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-form .form-field {
  margin-bottom: 15px;
  text-align: left;
}

.contact-form label {
  color: var(--accent-color);
  font-size: 14px;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: none;
  background: #333;
  color: var(--white);
  font-size: 16px;
}

.contact-form textarea {
  height: 100px;
  resize: none;
}

.subscribe-option {
  display: flex ;
  align-items: center !important;
  margin-top: 15px;
  font-size: 14px;
}

.subscribe-option input[type="checkbox"] {
  margin-right: 10px !important;
  accent-color: var(--accent-color);
}

.submit-button {
  background-color: var(--accent-color);
  border: none;
  color: var(--white);
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: var(--pink);
}

.error-message {
  color: #ff4f4f;
  font-size: 14px;
  margin-top: 10px;
}

.confirmation-message {
  color: var(--white);
}

.confirmation-message h3 {
  color: var(--accent-color);
}
