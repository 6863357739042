.order-management {
  padding: 20px;
  background-color: #1c1c1e;
  color: #f5f5f5;
  text-align: center;
}

.page-title {
  color: #bb86fc;
  margin-bottom: 20px;
}

.order-list {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #2c2c2e;
  border-radius: 8px;
  color: #f5f5f5;
}

.order-item {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #3c3c3e;
}

.order-item:last-child {
  border-bottom: none;
}

.order-item .MuiButton-root {
  background-color: #bb86fc;
  color: #1c1c1e;
}

/* White placeholder for input fields */
.order-item .MuiInputBase-input::placeholder {
  color: #ffffff !important;
  opacity: 0.8; /* Adjust opacity for better visibility */
}

/* Bold and white order status */
.order-item .order-status {
  font-weight: bold !important;
  color: #ffffff !important;
}
