.art-gallery {
  padding: 20px;
  background-color: #1c1c1e;
  color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-title {
  font-size: 2rem;
  color: #bb86fc;
  margin-bottom: 20px;
  text-align: center;
}

.art-thumbnail {
  background-size: cover;
  background-position: center;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.art-thumbnail:hover {
  transform: scale(1.05);
}

.art-title {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  color: #bb86fc;
  opacity: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-weight: bold;
  transition: opacity 0.3s ease;
}

.art-thumbnail:hover .art-title {
  opacity: 1;
}

/* Mobile responsive adjustments */
@media (max-width: 600px) {
  .art-thumbnail {
    height: 150px;
  }
}
