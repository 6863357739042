.blog-password {
  padding: 20px;
  background-color: #1c1c1e;
  color: #f5f5f5;
  min-height: 100vh;
  text-align: center;
}

.page-title {
  color: #bb86fc;
  margin-bottom: 20px;
}

.password-input {
  background-color: #2c2c2e;
  color: #f5f5f5;
  margin-bottom: 20px;
  width: 80%;
  max-width: 400px;
}

.submit-button {
  background-color: #bb86fc;
  color: #1c1c1e;
  margin-top: 10px;
}
