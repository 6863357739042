.art-detail-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: #1c1c1e;
  padding: 20px;
  border-radius: 8px;
  color: #f5f5f5;
  max-width: 90vw;
  margin: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.6);
}

.art-image {
  background-size: cover;
  background-position: center;
  max-width: 45vw;
  max-height: 75vh;
  border-radius: 8px;
  margin-bottom: 15px;
}

.art-info {
  text-align: left;
  width: 40vw;
  white-space: pre-line;
}

.art-description {
  max-height: 300px; /* Adjust as needed */
  overflow-y: auto;
  white-space: pre-wrap; /* Ensures line breaks and paragraphs are preserved */
  padding-right: 8px; /* Optional: space for scrollbar */
  color: #f5f5f5;
}

.art-info h5 {
  color: #bb86fc;
}

.artmodule-buttons {
  display: flex;
  gap: 10px;
}

/* Button styles */
button {
  background-color: #ff4081;
  color: #1c1c1e;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

button:hover {
  background-color: #d93670;
}

/* Placeholder text color for form fields */
.order-form input::placeholder {
  color: #f5f5f5 !important; /* White placeholder text */
  opacity: 0.8; /* Slight transparency for better readability */
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .art-detail-modal {
    width: 90%;
    flex-direction: column;
  }

  .art-image {
    height: 200px;
  }
}
